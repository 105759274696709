import React from "react";
import SectionHeader from "./SectionHeader";
//${process.env.PUBLIC_URL} + "/Self.png"

const technologies = [
  "Java",
  "ReactJS",
  "Python",
  "AWS",
  "Linux",
  "Git",
  "SQL",
  "C#",
  "XML",
  "APIs",
];

const AboutMe = (props) => {
  const renderTechnologyList = () => {
    return technologies.map((item, index) => {
      return (
        <li className="technology-item" key={index}>
          <span className="material-icons bullet-point">chevron_right</span>
          <span>{item}</span>
        </li>
      );
    });
  };

  return (
    <div className="about-me" id="About">
      <div className="left-container">
        <div className="about-me-text">
          <SectionHeader label="About Me" />
          <p>
            I'm a passionate software developer based in the Southeastern United
            States (TN) with a background in software engineering.
          </p>
          <p>
            In 2018, I ventured into web development and computer science,
            acquiring skills through higher education and professional
            experiences. I furthered my education by obtaining a bachelor's
            degree in Computer Science, while simultaneously working as a
            full-time developer.
          </p>
          <p></p>
        </div>

        <div className="technologies">
          <span className="sub-header">Technologies:</span>
          <ul className="technology-list">{renderTechnologyList()}</ul>
        </div>
      </div>

      <div className="right-container">
        <div className="image-container">
          <div className="stylized-border"></div>
          <img
            className="self-portrait"
            src={process.env.PUBLIC_URL + "/Self.png"}
            alt="A Self portrait of Alec"
          ></img>
          <div className="overlay-black"></div>
          <div className="overlay-yellow"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
