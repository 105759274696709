import React from "react";
import ProjectCard from "./ProjectCard";
import SectionHeader from "./SectionHeader";

class ProjectItem {
  constructor(id, name, description, technologies, github = null) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.technologies = technologies;
    this.github = github;
  }
}

const projectList = [
  new ProjectItem(
    0,
    "Tower Defense Game",
    "This was my capstone project for CSC478 Software Engineering course",
    ["Javascript", "React.js", "Jest"],
    "https://github.com/Alecprice/CSC478_-Software-Engineering-Capstone"
  ),
  new ProjectItem(
    1,
    "robofriends",
    "This was an exercise to practice using APIs and React",
    ["ReactJS"],
    "https://github.com/Alecprice/robofriends"
  ),
  new ProjectItem(
    2,
    "AntSim",
    "This was the final in my CSC385 Algorithms and Data Structures course.",
    ["JAVA"],
    "https://github.com/Alecprice"
  ),
];

const Projects = (props) => {
  const renderProjectCards = () => {
    return projectList.map((item) => {
      return (
        <ProjectCard
          key={item.id}
          name={item.name}
          description={item.description}
          technologies={item.technologies}
          github={item.github}
        />
      );
    });
  };

  return (
    <div className="projects" id="Projects">
      <div className="header-container">
        <SectionHeader label="Projects" />
      </div>

      <div className="project-list">{renderProjectCards()}</div>
    </div>
  );
};

export default Projects;
