import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import SectionHeader from "./SectionHeader";

class ExperienceItem {
  constructor(id, name, title, datesWorked, bulletPoints, shortName = name) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.datesWorked = datesWorked;
    this.bulletPoints = bulletPoints;
    this.shortName = shortName;
  }
}

let experienceItemsDefault = [
  new ExperienceItem(
    4,
    "Q2",
    "Application Support Engineer",
    "Oct. 2021 - Present",
    [
      "Provide quality assurance best practices to our customers to assure our Extract, Transfer, and Load Processes are completed correctly and in a timely manner.",
      "Perform Create, Read, Update, and Delete operations in Microsoft SQL Server Management.",
      "Contribute to our in-house software engineer team to create tools and automation processes in C#, Python, and JavaScript.",
      "Modify existing python scripts to optimize performance or migrate jobs to different SFTP servers.",
      "Configure IAM Roles and S3Buckets.",
      "Review Application Support Analyst changes before publishing.",
      "Create Jira Tickets to be used with version control and quality assurance in efforts to track changes made and document my thought process for peers’ future reference.",
      "Take part in a leadership program that creates articles that walk through the process of changes to help establish a workflow for new employees or employees that might be struggling to reach their benchmarks.",
    ]
  ),
  new ExperienceItem(
    3,

    "Carter County Sheriff’s Department",
    "Information Technology Developer ",
    "Sep. 2020 - Nov. 2021",
    [
      "Provide General IT support for the sheriff’s office and various other entities within the same governing body such as Townhall, Elizabethton Police Department, Court Clerk’s Office, and various others.",
      "Develop solutions (Programs) to solve problems, such as a contacts app to contact various employees within the sheriff’s department.",
      "General Network administration Duties such as resetting passwords and provisioning laptops and phones for employees. Maintain databases with past and present employee records.",
      "Installing hardware consisting of but not limited to switches, routers, and running ethernet/fiber to access points to create a better user experience with internet signal within the sheriff’s department.",
      "Train Employees on new and existing software.",
    ]
  ),
  new ExperienceItem(
    0,
    "Business Information Systems",
    "Web Developer",
    "March. 2020 - Nov. 2020",
    [
      "Troubleshoot new and pre-existing software.",
      "Collaborate with other departments to fix bugs and add enhancement features to websites to better user experience.",
      "UX/UI development for responsive sites across all platforms and browsers.",
      "Use SVN & GIT for version control.",
      "Create, update, and maintain databases.",
      "Manage and complete paperwork and overall office administration operations.",
      "Prepare and distribute team-based communications to foster collaboration and enhance workflow.",
    ]
  ),
  new ExperienceItem(
    1,
    "University Of Illinois, Springfield",
    "Student",
    "August 2020 - March 2022",
    ["Received Bachelor's of Computer Science", "3.94 GPA"],
    "Univ. Illinois, Springfield"
  ),
  new ExperienceItem(
    2,
    "Amazon Web Services(AWS)",
    "Certification",
    "October 2022 - October 2025",
    ["AWS Certified Cloud Practitioner"]
  ),
];

const Work = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  //I'm never needing to set experience items so I don't include the set function
  const [experienceItems] = useState(experienceItemsDefault);
  const [selected, setSelected] = useState(experienceItems[0]);
  const handleSelection = (e) => {
    setSelected(experienceItems[e.currentTarget.value]);
  };

  const renderExperienceList = () => {
    return experienceItems.map((item, index) => {
      return (
        <li
          onClick={handleSelection}
          key={item.id}
          value={index}
          className={`work-selector-item ${
            selected.id === item.id ? "selected" : ""
          }`}
        >
          {isMobile ? item.shortName : item.name}
        </li>
      );
    });
  };

  const renderExperienceBulletPoints = () => {
    return selected.bulletPoints.map((item, index) => {
      return (
        <li className="experience-item" key={index}>
          <span className="material-icons bullet-point">chevron_right</span>
          <span>{item}</span>
        </li>
      );
    });
  };

  const renderExperienceInformation = () => {
    return (
      <div className="selected-information">
        {/* Labels */}
        <span className="job-title">
          {selected.title}, <span className="company">{selected.name}</span>
        </span>
        <span className="dates-worked">{selected.datesWorked}</span>

        {/* Information && Bullet Points */}
        <ul className="experience-list">{renderExperienceBulletPoints()}</ul>
      </div>
    );
  };

  return (
    <div className="work" id="Work">
      <SectionHeader label="Work && Education" />
      <div className="work-container">
        <div className="work-selector">
          <ul className="work-selector-list">{renderExperienceList()}</ul>
        </div>

        {renderExperienceInformation()}
      </div>
    </div>
  );
};

//notes for setting up layout as an example DELETE
<div className="selected-information">
  {/* Labels */}
  <span className="job-title">
    Software Engineer, <span className="company">Payality</span>
  </span>
  <span className="dates-worked">Nov 2019 - Present</span>

  {/* Information && Bullet Points */}
  <ul className="experience-list">
    <li className="experience-item">
      <span className="material-icons bullet-point">chevron_right</span>
      <span>
        Using a MERN Stack (MongoDB, Express, React, Node) created 2 web apps
        that changed the way the company receives and manipulates data
      </span>
    </li>
    <li className="experience-item">
      <span className="material-icons bullet-point">chevron_right</span>
      <span>
        Implemented and maintained a 3rd party API that allowed users to enter
        in information in our system and automatically have it populate the
        information in other systems.
      </span>
    </li>
    <li className="experience-item">
      <span className="material-icons bullet-point">chevron_right</span>
      <span>
        Created a custom quote engine for the sales team using Node.js that
        allowed for quick and accurate quotes for the end user
      </span>
    </li>
  </ul>
</div>;

export default Work;
